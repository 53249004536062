import {ValidatorFn} from '@angular/forms';

export function validateForm(form) {
  Object.keys(form.controls).forEach(field => { // {1}
    const control = form.get(field);            // {2}
    control.markAsTouched({onlySelf: true});       // {3}
  });
  return form.valid;
}

export function Loader(value) {
  if (value) {
    $('#loader').show();
  } else {
    $('#loader').hide();
  }
}

export function KebabCase(name) {
  return name.replace(/\s+/g, '-').toLowerCase();
}

export function ToTitleCase(name) {
  return name.replace(/-/g, ' ').toLowerCase().replace(/(?:^|[\s-/])\w/g, function (match) {
    return match.toUpperCase();
  })
}

export interface BooleanFn {
  (): boolean;
}

export function ConditionalValidator(predicate: BooleanFn,
                                     validator: ValidatorFn,
                                     errorNamespace?: string): ValidatorFn {
  return (formControl => {
    if (!formControl.parent) {
      return null;
    }
    let error = null;
    if (predicate()) {
      error = validator(formControl);
    }
    if (errorNamespace && error) {
      const customError = {};
      customError[errorNamespace] = error;
      error = customError
    }
    return error;
  })
}
