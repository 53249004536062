import { Component, OnInit } from '@angular/core';
// import { ConfirmationService } from 'primeng/api';
import {Router} from '@angular/router';
import {JWTAuthService} from '../services/jwt-auth.service';

@Component({
  selector: 'app-workspace-create',
  templateUrl: './workspace-create.component.html',
  styleUrls: ['../auth-styles.scss', './workspace-create.component.css'],
})
export class WorkspaceCreateComponent implements OnInit {
  isLoading: boolean = false;
  form: any = {};

  constructor(
    // private confirmationService: ConfirmationService,
    private authService: JWTAuthService,
    private router: Router,
  ) {}

  ngOnInit(): void {}

  /**
   * Workspace create form submit handler
   * */
  onSubmit(): void {
    // this.confirmationService.confirm(
    //   Object.assign(
    //     {
    //       header: 'Confirmation Required',
    //       message: `Are you sure you want to continue with <b>"${this.form.workSpace}"</b>?`,
    //       icon: 'pi pi-info-circle',
    //       rejectButtonStyleClass: 'p-button-warning p-button-outlined',
    //     },
    //     {
    //       accept: () => {
    //         this.confirmSubmit();
    //       },
    //       reject: () => {},
    //     },
    //   ),
    // );
  }

  /**
   * Workspace create form submit confirmation handler
   * @param form
   * */
  private confirmSubmit() {
    this.isLoading = true;
    this.authService.registerUser(this.form).subscribe({
      next: () => {
        this.router.navigateByUrl('/auth/sign-in');
        this.isLoading = false;
      }, error: () => {
        this.isLoading = false;
      }
    })
  }
}
