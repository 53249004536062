import {Component, OnInit} from '@angular/core';
// import {HandleLogoError} from '../../util/helpers';

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.css']
})
export class LogoComponent implements OnInit {
  logo = '';

  constructor() {
  }

  ngOnInit(): void {
    this.logo = 'https://auth.energy.gov.lk/auth/resources/s8oon/login/sbs-theme/img/sbs-logo.png';
  }

  // handleLogoError(event: any) {
  //   HandleLogoError(event)
  // }

}
