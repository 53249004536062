import { Component, OnInit } from "@angular/core";
import { JWTAuthService } from "../services/jwt-auth.service";
// import { Store } from "@ngrx/store";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: "app-reset-password",
  templateUrl: "./reset-password.component.html",
  styleUrls: ["../auth-styles.scss", "./reset-password.component.css"],
})
export class ResetPasswordComponent implements OnInit {
  companyConfig = this.authService.companyConfig;
  form: any = {};
  isLoading: boolean = false;

  constructor(
    private authService: JWTAuthService,
    private route: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService,
  ) {
    /**
     * Get token from url
     * */
    this.route.queryParams.subscribe((params) => {
      const token = params["token"];
      if (token) {
        this.form.token = token;
      }
    });
  }

  ngOnInit(): void {}

  /**
   * Submit reset password form
   * */
  onSubmit() {
    // http://localhost:4200/#/auth/reset-password?token=2d87492a-87c9-4e40-a3e8-8d7a26b836d3
    this.isLoading = true;
    this.spinner.show();
    let data = Object.assign({}, this.form);
    delete data.matchingPassword;
    this.authService.resetPassword(data).subscribe({
      next: () => {
        this.router.navigateByUrl("/auth/sign-in");
        this.isLoading = false;
        this.spinner.hide();
      },
      error: () => {
        this.isLoading = false;
        this.spinner.hide();
      },
    });
  }
}
