import { Injectable } from '@angular/core';
import {TokenStorageService} from "../../auth/services/token-storage.service";
import {NgxPermissionsService} from "ngx-permissions";
import {ActivatedRoute, Router} from "@angular/router";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import jwt_decode from 'jwt-decode';
import {environment} from "../../../environments/environment";

const USER_KEY = 'auth-user'
const PERMISISON_KEY = 'per';

@Injectable()
export class AuthService {

  private timeoutId: any;
  private company:any;
  private companyConfiguration:any;


  constructor(
    private tokenStorageService: TokenStorageService,
    private ngxPermissionsService: NgxPermissionsService,
    private route: ActivatedRoute,
    private router: Router,
    private http:HttpClient
  ) {}

  load(): Promise<boolean> {
    return new Promise<boolean>(async (resolve) => {
      debugger
      let data = {
        user: this.tokenStorageService.getUser(),
        token: this.tokenStorageService.getToken(),
      };
      debugger
      if (data.user && data.token) {
        let headers = new HttpHeaders({'Authorization': 'Bearer ' + data.token,'Content-Type': 'application/json'});
        let tokenEndpoint = environment.authUrl + "authenticate";

        let authData = await this.http.post<any>(tokenEndpoint, {"email": data.user.email},{headers:headers})
          .toPromise().catch(err => this.logout());

        this.ngxPermissionsService.loadPermissions(authData.permission);
      } else {
        const currentURL = window.location.href;
        const searchString = 'auth/sign-up';
        if (currentURL.includes(searchString)) {
        } else {
          this.logout();
        }
      }

      resolve(true);
      return data;

    });

  }


  login(token: string): void {
    this.tokenStorageService.saveToken(token);
    this.router.navigate(['/']);
  }

  logout() {
    this.tokenStorageService.signOut();
    clearTimeout(this.timeoutId);
    this.router.navigate(['/auth/sign-in']);
  }

  resetTimeout() {
    clearTimeout(this.timeoutId);
    this.timeoutId = setTimeout(() => {
      this.logout();
    }, 600000); // 10 minutes in milliseconds
  }

  getToken() {
    return this.tokenStorageService.getToken();
  }

  refreshToken() {
    // return this.initializerService.updateToken();
  }

  isAuthenticated() {
    const token = this.tokenStorageService.getToken();
    // Check if the token is not expired
    if (token) {
      // You can add additional checks here if needed
      let decodedToken: any = jwt_decode(token);
      if (!decodedToken['X-TenantID']) {
        return false;
      }
      const currentTimestamp = Date.now() / 1000;
      return decodedToken.exp > currentTimestamp;
    }
    return false;
  }

  getAuthUser(){
    let user = localStorage.getItem(USER_KEY)
      ? localStorage.getItem(USER_KEY)
      : '';
    if (user) {
      return JSON.parse(user);
    }
    return '';
  }

  getPermission(){
    let permission = localStorage.getItem(PERMISISON_KEY)
      ? localStorage.getItem(PERMISISON_KEY)
      : '';
    if (permission) {
      return JSON.parse(permission);
    }
    return '';
  }


  getCompanyConfiguration():any{
    return this.companyConfiguration;
  }

  getCompany():any{
    return this.company;
  }

}
