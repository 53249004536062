<div class="wrapper nav-collapsed menu-collapsed" [dir]="options.direction">
  <div class="app-sidebar" data-active-color="black" data-background-color="purple-bliss" data-image="assets/img/sidebar-bg/01.jpg">
    <app-sidebar></app-sidebar>
    <div class="sidebar-background"></div>
  </div>
  <app-navbar></app-navbar>
  <div class="main-panel">
    <div class="main-content">
      <div class="content-wrapper">
        <div class="container-fluid" style="position: relative">
          <div  class="text-center preloader" id="loader">
            <div id="loader-icon-div">
              <i class="fa ft-loader fa fa-spin" style=" font-size: 3em;"></i>
              <span class="sr-only">Loading...</span>
            </div>
          </div>
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
    <app-footer></app-footer>
  </div>
  <app-notification-sidebar></app-notification-sidebar>
  <app-customizer (directionEvent)="getOptions($event)"></app-customizer>
</div>
