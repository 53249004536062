/**
 * User interface for the user model
 * */
export class User {
  workSpace?: string;
  roleCount?: string;
  tenantId?: string;
  killBillTenant?: string;
  apiKey?: string;
  apiSecret?: string;
  comment?: string;
  reason?: string;
  createdBy?: string;
  plan?: string;
  workSpaceCount?: any;
  account?: string;
  subscription?: string;
  acceptTerms: string = '';
  signUpRequest: Subscription;

  constructor() {
    this.signUpRequest = new Subscription();
  }
}

export class Subscription {
  id: any;
  displayName: string = '';
  firstName: string = '';
  lastName?: string;
  tenantId?: string;
  birthday?: string;
  country?: string;
  gender?: string;
  location?: string;
  companyName?: string;
  registrationNo?: string;
  planId?: string;
  username: string = '';
  email?: string = '';
  branchId?: number;
  socialProvider: string = 'LOCAL';
  password: string = '';
  matchingPassword: string = '';
  role: number = 1;
}

