

<!--<link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css">-->
<div class="login-page  ">
  <div class="form-card">
    <div class="form-card-header mb-4 text-center">
      <img src="https://www.energy.gov.lk/templates/assets/img/logo.png" alt="Logo" class="img-fluid">
    </div>

    <form id="sign-in-form" class="form"
          #f="ngForm"
          (ngSubmit)="f.form.valid && onSubmit()"
          name="form"
          novalidate>
      <div class="form-group">
        <label for="email">Email</label>
        <input class="form-control input-bottom-border"  #email="ngModel"
               [(ngModel)]="form.email"

               id="email"
               name="email"
               inputmode="email"
               required
               type="email">

        <div *ngIf="f.submitted && email.invalid" class="validation-msg">
          <div *ngIf="email?.errors?.required">Email is required</div>
          <div *ngIf="email?.errors?.email">
            Email must be a valid email address
          </div>
        </div>
      </div>

      <div class="form-group">
        <label for="password">Password</label>
        <input   class="form-control input-bottom-border"  #password="ngModel"
               [(ngModel)]="form.password"

               id="password"
               minlength="6"
               name="password"
               required
               type="password">

        <div *ngIf="f.submitted && password.invalid" class="validation-msg">
          <div *ngIf="password.errors?.required">Password is required</div>
          <div *ngIf="password.errors?.minlength">
            Password must be at least 6 characters
          </div>
        </div>

      </div>

      <div class="form-check mb-3">
        <input type="checkbox" id="remember-me" class="form-check-input">
        <label for="remember-me" class="form-check-label">Remember me</label>
      </div>

      <div>
        <span class="loader" *ngIf="isLoading"></span>
        <button [disabled]="isLoading" class="form-button" id="signInBtn">
          {{isLoading ? '' : 'Sign In'}}
        </button>


      </div>


      <p class="text-center mt-3"  id="signUplink" routerLink="/auth/sign-up">New user?  Register</p>
    </form>
  </div>
</div>
