import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl, ValidationErrors } from '@angular/forms';

@Directive({
  selector: '[appConfirmPassword]',
  providers: [{ provide: NG_VALIDATORS, useExisting: ConfirmPasswordDirective, multi: true }]
})
export class ConfirmPasswordDirective implements Validator {
  @Input('appConfirmPassword') confirmControlName: string = '';

  /**
   * Validates the confirm password
   * */
  validate(control: AbstractControl): ValidationErrors | null {
    // const confirmControl = control.parent?.get(this.confirmControlName);
    //
    // if (confirmControl && control.value !== confirmControl.value) {
    //   return { passwordMismatch: true };
    // }

    return { passwordMismatch: true };
  }
}
