import { Component, OnInit } from '@angular/core';
import {JWTAuthService} from '../services/jwt-auth.service';

@Component({
  selector: 'app-terms-of-service',
  templateUrl: './terms-of-service.component.html',
  styleUrls: ['../auth-styles.scss', './terms-of-service.component.css']
})
export class TermsOfServiceComponent implements OnInit {


  constructor(
    private authService: JWTAuthService,
  ) {
  }

  ngOnInit(): void {
  }


}
