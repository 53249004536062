import {RouteInfo} from './sidebar.metadata';

export const ROUTES: RouteInfo[] = [

  {
    path: '/dashboard',
    title: 'Dashboard',
    icon: 'ft-home',
    class: '',
    badge: '',
    badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1',
    isExternalLink: false,
    allowPermissions: [],
    submenu: [],
  },
  // {
  //   path: '/map/map', title: 'Map', icon: 'ft-edit', class: '', badge: '',
  //   badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false,
  //   allowPermissions: [],
  //   submenu: [],
  // },
  // {
  //   path: '/company/company-registration', title: 'Company Profile', icon: 'ft-inbox', class: '', badge: '',
  //   badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false,
  //   allowPermissions: ['register_company'],
  //   submenu: [],
  // },

  {
    path: '',
    title: 'Company Profile',
    icon: 'ft-inbox',
    class: 'has-sub',
    badge: '',
    badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1',
    isExternalLink: false,
    allowPermissions: ['register_company'],
    submenu: [
      {
        path: '/company/company-registration',
        title: 'Profile Details',
        icon: '',
        class: '',
        badge: '',
        badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1',
        isExternalLink: false,
        allowPermissions: ['register_company','create_company_admin'],
        submenu: [],
      },
      {
        path: '/company/company-change-request',
        title: 'Create Change Request',
        icon: '',
        class: '',
        badge: '',
        badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1',
        isExternalLink: false,
        allowPermissions: ['register_company'],
        submenu: [],
      },
      {
        path: '/company/name-change-list',
        title: 'Change Requests List',
        icon: '',
        class: '',
        badge: '',
        badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1',
        isExternalLink: false,
        allowPermissions: [],
        submenu: [],
      },
    ],
  },

  {
    path: '/my-projects/my-project-list',
    title: 'My projects',
    icon: 'ft-folder',
    class: '',
    badge: '',
    badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1',
    isExternalLink: false,
    allowPermissions: ['list_my_projects', 'create_project'],
    submenu: [],
  },

  {
    path: '',
    title: 'General Request',
    icon: 'ft-maximize-2',
    class: 'has-sub',
    badge: '',
    badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1',
    isExternalLink: false,
    allowPermissions: [
      'general_request'
    ],
    submenu: [
      {
        path: '/my-projects/six-month-extension',
        title: 'Six Month Extension',
        icon: 'ft-log-in',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false, allowPermissions: ['six_month_extension_list'],
        submenu: []
      },
      {
        path: '/my-projects/special-provisional',
        title: 'Special Provisional',
        icon: 'ft-log-in',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false, allowPermissions: ['view_special_provisional_requests'],
        submenu: []
      },

      {
        path: '/my-projects/special-energy-permit',
        title: 'Special Energy Permit',
        icon: 'ft-log-in',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false, allowPermissions: ['list_special_energy_permit'],
        submenu: []
      },

      // {
      //   path: '',
      //   title: '02nd Energy Permit',
      //   icon: 'ft-log-in',
      //   class: '',
      //   badge: '',
      //   badgeClass: '',
      //   isExternalLink: false, allowPermissions: [],
      //   submenu: []
      // },

      {
        path: '/bonded-warehouse/bonded-warehouse-create',
        title: 'Bonded Warehouse List',
        icon: 'ft-log-in',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        allowPermissions: ['bonded_warehouse_create'],
        submenu: []
      },


      // {
      //   path: '/my-projects/extension-request-list',
      //   title: 'Six Month Extension 2',
      //   icon: 'ft-log-in',
      //   class: '',
      //   badge: '',
      //   badgeClass: '',
      //   isExternalLink: false, allowPermissions: [],
      //   submenu: []
      // },
    ],
  },

  {
    path: '/company/company-list', title: 'Company List', icon: 'ft-list', class: '', badge: '',
    badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false,
    allowPermissions: ['list_companies'],
    submenu: [],
  },

  {
    path: '/my-tasks/my-tasks-list',
    title: 'My Tasks',
    icon: 'ft-folder',
    class: '',
    badge: '',
    badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1',
    isExternalLink: false,
    allowPermissions: ['list_my_tasks'],
    submenu: [],
  },

  {
    path: '/my-projects/project-list',
    title: 'All projects',
    icon: 'ft-copy',
    class: '',
    badge: '',
    badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1',
    isExternalLink: false,
    allowPermissions: ['list_all_projects'],
    submenu: [],
  },

  {
    path: '',
    title: 'Requests',
    icon: 'ft-maximize-2',
    class: 'has-sub',
    badge: '',
    badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1',
    isExternalLink: false,
    allowPermissions: [
      'request'
    ],
    // allowPermissions: [
    //   'view_6_months_extension_request',
    //   'make_decision_on_6_months_extensions',
    //   'view_special_provisional_requests',
    //   'make_decision_on_special_provisional_requests',
    //   'view_special_energy_permit_requests',
    //   'make_decision_on_special_energy_permit_extensions',
    //   'bonded_warehouse_list',
    // ],
    submenu: [
      {
        path: '/my-projects/six-month-extension',
        title: 'Six Month Extension',
        icon: 'ft-log-in',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false, allowPermissions: [],
        submenu: []
      },
      {
        path: '/my-projects/special-provisional',
        title: 'Special Provisional',
        icon: 'ft-log-in',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false, allowPermissions: ['view_special_provisional_requests'],
        submenu: []
      },

      {
        path: '/my-projects/special-energy-permit',
        title: 'Special Energy Permit',
        icon: 'ft-log-in',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false, allowPermissions: ['view_special_energy_permit_requests'],
        submenu: []
      },

      // {
      //   path: '',
      //   title: '02nd Energy Permit',
      //   icon: 'ft-log-in',
      //   class: '',
      //   badge: '',
      //   badgeClass: '',
      //   isExternalLink: false, allowPermissions: [],
      //   submenu: []
      // },

      {
        path: '/bonded-warehouse/bonded-warehouse-list',
        title: 'Bonded Warehouse List',
        icon: 'ft-log-in',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false, allowPermissions: ['bonded_warehouse_list'],
        submenu: []
      },

      // {
      //   path: '/my-projects/extension-request-list',
      //   title: 'Six Month Extension 2',
      //   icon: 'ft-log-in',
      //   class: '',
      //   badge: '',
      //   badgeClass: '',
      //   isExternalLink: false, allowPermissions: [],
      //   submenu: []
      // },
    ],
  },


  {
    path: '',
    title: 'Approvals',
    icon: 'ft-maximize-2',
    class: 'has-sub',
    badge: '',
    badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1',
    isExternalLink: false,
    allowPermissions: [
      'list_referring_approvals',
      'list_additional_referring',
      'list_external_approval'
    ],
    submenu: [
      {
        path: '/referring/referring-approval-list',
        title: 'Referring Approvals',
        icon: 'ft-log-in',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false, allowPermissions: ['list_referring_approvals'],
        submenu: []
      },
      {
        path: '/referring/additional-referring',
        title: 'Additional Referring',
        icon: 'ft-log-in',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false, allowPermissions: ['list_additional_referring'],
        submenu: []
      },

      {
        path: '/external/external-approval-list',
        title: 'External Approvals',
        icon: 'ft-log-in',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false, allowPermissions: ['list_external_approval'],
        submenu: []
      },

    ],
  },

  {
    path: '/company/name-change-list',
    title: 'Change Requests',
    icon: 'ft-folder',
    class: '',
    badge: '',
    badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1',
    isExternalLink: false,
    allowPermissions: ['view_change_request_list'],
    submenu: [],
  },

  {
    path: '/inquiries/inquiries-list',
    title: 'Inquiries / Complains',
    icon: 'ft-book',
    class: '',
    badge: '',
    badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1',
    isExternalLink: false,
    allowPermissions: ['inquiries_complains_list'],
    submenu: [],
  },

  {
    path: '', title: 'Message', icon: 'ft-bookmark', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
    allowPermissions: ['admin_message'],
    submenu: [
      {
        path: '/inquiries/sent-messages',
        title: 'Send',
        icon: 'ft-log-in',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false, allowPermissions: ['list_message'],
        submenu: []
      },
      {
        path: '/inquiries/inquiries-list',
        title: 'Received',
        icon: 'ft-book',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false, allowPermissions: ['list_inquiries'],
        submenu: []
      },

    ]
  },

  {
    path: '',
    title: 'Committee',
    icon: 'ft-users',
    class: 'has-sub',
    badge: '',
    badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1',
    isExternalLink: false,
    allowPermissions: ['list_committees'],
    submenu: [

      {
        path: '/committee/committee-list',
        title: 'PAC',
        icon: 'ft-pocket',
        class: 'has-sub',
        badge: '',
        badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1',
        isExternalLink: false,
        allowPermissions:
          [
            'create_committee',
            'list_committees',
            'edit_committee',
            'view_pac_approvals',
            'make_decision_on_pac_approvals',
            'view_pac_members',
            'make_decision_on_pac_member_registration',
          ],
        submenu: [
          {
            path: '/company/pac-company-list',
            title: 'PAC Member List',
            icon: 'ft-more-vertical',
            class: '',
            badge: '',
            badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1',
            isExternalLink: false,
            allowPermissions: [
              'view_pac_members',
              'make_decision_on_pac_member_registration'
            ],
            submenu: [],
          },
          {
            path: '/committee/committee-list',
            title: 'Create & List Committee',
            icon: 'ft-pocket',
            class: '',
            badge: '',
            badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1',
            isExternalLink: false,
            allowPermissions:
              [
                'create_committee',
                'list_committees',
                'edit_committee',
                'view_pac_approvals',
                'make_decision_on_pac_approvals'
              ],
            submenu: [],
          },
        ],
      },
      {
        path: '/committee/appeal-committee-list',
        title: 'Appeal',
        icon: 'ft-play',
        class: '',
        badge: '',
        badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1',
        isExternalLink: false,
        allowPermissions: [
          'create_appeal_committee',
          'list_appeal_committees',
          'edit_appeal_committee',
          'view_appeal_committee_approvals',
          'make_decision_on_appeal_committee_approvals'
        ],
        submenu: [],
      },
      {
        path: '/boards-paper/committee-boards-list',
        title: 'Boards',
        icon: 'ft-share-2',
        class: '',
        badge: '',
        badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1',
        isExternalLink: false,
        allowPermissions: [
          'create_board_committee',
          'list_board_committees',
          'edit_board_committee',
          'view_board_committee_approvals',
          'make_decision_on_board_committee_approvals'
        ],
        submenu: [],
      },
    ],
  },

  {
    path: '/payments/payment-approval-list', title: 'All Payments', icon: 'ft-home', class: '',
    badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1',
    isExternalLink: false, allowPermissions: ['list_all_payments'],
    submenu: [],
  },

  {
    path: '', title: 'Admin', icon: 'ft-zap', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
    allowPermissions: ['admin_view'],
    submenu: [
      {
        path: '/vat-configuration/configuration',
        title: 'Vat Configuration',
        icon: 'ft-shield',
        class: '',
        badge: '',
        badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1',
        isExternalLink: false,
        allowPermissions: ['vat_configuration'],
        submenu: [],
      },
    ]
  },

  {
    path: '', title: 'Form Settings', icon: 'ft-settings', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
    allowPermissions: ['form_setting_view'],
    submenu: [
      {
        path: '/sections/section-list',
        title: 'Section List',
        icon: 'ft-layers',
        class: '',
        badge: '',
        badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1',
        isExternalLink: false,
        allowPermissions: ['list_section'],
        submenu: [],
      },

      {
        path: '/forms/form-list',
        title: 'Form List',
        icon: 'ft-layout',
        class: '',
        badge: '',
        badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1',
        isExternalLink: false,
        allowPermissions: ['list_forms'],
        submenu: [],
      }
    ]
  },

  {
    path: '', title: 'User Management', icon: 'icon-user', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
    allowPermissions: ['view_user', 'view_role', 'view_permission'],
    submenu: [
      {
        path: '/usermanagement/permission',
        title: 'Permissions',
        icon: 'ft-log-in',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false, allowPermissions: ['view_permission'],
        submenu: []
      },
      {
        path: '/usermanagement/role',
        title: 'Roles',
        icon: 'ft-book',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false, allowPermissions: ['view_role'],
        submenu: []
      },
      {
        path: '/usermanagement/user',
        title: 'Users',
        icon: 'ft-user',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false, allowPermissions: ['view_user'],
        submenu: []
      },
    ]
  },


  // {
  //   path: '/my-projects/change-ownership-list', title: 'Project Transfer List', icon: 'ft-folder', class: '', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1',
  //   isExternalLink: false,allowPermissions: ['list_my_projects'],
  //   submenu: [],
  // },


  // {
  //   path: '', title: 'Inquiries / Complains', icon: 'ft-bookmark', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
  //   allowPermissions: ['view_inquiries'],
  //   submenu: [
  //     // {
  //     //   path: '/inquiries/inquiries-list',
  //     //   title: 'Send',
  //     //   icon: 'ft-log-in',
  //     //   class: '',
  //     //   badge: '',
  //     //   badgeClass: '',
  //     //   isExternalLink: false, allowPermissions: ['create_inquiries', 'view_inquiries'],
  //     //   submenu: []
  //     // },
  //     {
  //       path: '/inquiries/inquiries-list',
  //       title: 'Received',
  //       icon: 'ft-book',
  //       class: '',
  //       badge: '',
  //       badgeClass: '',
  //       isExternalLink: false, allowPermissions: ['view_inquiries', 'inquiries_list'],
  //       submenu: []
  //     },
  //
  //   ]
  // },


  {
    path: '/payments/my-payment-list', title: 'My Payments', icon: 'ft-home', class: '', badge: '',
    badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1',
    isExternalLink: false, allowPermissions: ['list_my_payments'],
    submenu: [],
  },

  // {
  //   path: '/form-wizard/form-wizard', title: 'form-wizard', icon: 'ft-home', class: '', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false,allowPermissions: [],
  //   submenu: [],
  // },
  //
  // {
  //   path: '/sample/sample-list', title: 'Sample List', icon: 'ft-home', class: '', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false,allowPermissions: [],
  //   submenu: [],
  // },


  // {
  //   path: '/customer/customer-create', title: 'Test form', icon: 'ft-home', class: '', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false,allowPermissions: [],
  //   submenu: [],
  // },

  // {
  //   path: '/form/create/frm-4', title: 'Form 4', icon: 'ft-home', class: '', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false,allowPermissions: [],
  //   submenu: [],
  // },
  // {
  //   path: '/form/create/customer', title: 'Customer', icon: 'ft-home', class: '', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false,allowPermissions: [],
  //   submenu: [],
  // },

  // {
  //   path: '/log/system-log',
  //   title: 'System Logs',
  //   icon: 'icon-flag',
  //   class: '',
  //   badge: '',
  //   badgeClass: '',
  //   isExternalLink: false,
  //   submenu: [],
  //   allowPermissions: ['view_permission']
  // },


];
