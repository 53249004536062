import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { SignUpComponent } from "./sign-up/sign-up.component";
import { SignInComponent } from "./sign-in/sign-in.component";
import { ForgotPasswordComponent } from "./forgot-password/forgot-password.component";
import { ResetPasswordComponent } from "./reset-password/reset-password.component";
import { TermsOfServiceComponent } from "./terms-of-service/terms-of-service.component";
import { VerificationComponent } from "./verification/verification.component";
import { TwoFactorVerificationComponent } from "./two-factor-verification/two-factor-verification.component";
import { WorkspaceCreateComponent } from "./workspace-create/workspace-create.component";

const routes: Routes = [
  {
    path: "sign-in",
    component: SignInComponent,
    data: {
      title: "Sign In",
    },
  },
  {
    path: "forgot-password",
    component: ForgotPasswordComponent,
    data: {
      title: "Forgot Password",
    },
  },
  {
    path: "reset-password",
    component: ResetPasswordComponent,
    data: {
      title: "Reset Password",
    },
  },
  {
    path: "terms-of-service",
    component: TermsOfServiceComponent,
    data: {
      title: "Terms of Service",
    },
  },
  {
    path: "privacy-policy",
    component: TermsOfServiceComponent,
    data: {
      title: "Privacy Policy",
    },
  },
  {
    path: "verification",
    component: VerificationComponent,
    data: {
      title: "Verification",
    },
  },
  {
    path: "two-factor-verification/:email",
    component: TwoFactorVerificationComponent,
    data: {
      title: "Two Factor Verification",
    },
  },
  {
    path: "create-workspace",
    component: WorkspaceCreateComponent,
    data: {
      title: "Create Workspace",
    },
  },

  {
    path: "sign-up",
    component: SignUpComponent,
    data: {
      title: "Sign up",
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule {}
