import {Component, OnInit} from '@angular/core';
import {ROUTES} from './sidebar-routes.config';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {AppService} from '../../app.service';
import {Loader} from '../../util/helpers';
import {FormService} from '../services/form.service';

declare var $: any;

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
})

export class SidebarComponent implements OnInit {
  public menuItems: any[];
  private formList: any[];
  tourData: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public translate: TranslateService,
    public appService: AppService,
    private formService: FormService,
  ) {

  }

  ngOnInit() {
    $.getScript('./assets/js/app-sidebar.js');
    this.menuItems = ROUTES.filter(menuItem => menuItem);
    this.getTourData();
    //uncomment below function to load dynamic forms list
    // this.getFormList();
  }

  // NGX Wizard - skip url change
  ngxWizardFunction(path: string) {
    if (path.indexOf('forms/ngx') !== -1) {
      this.router.navigate(['forms/ngx/wizard'], {skipLocationChange: false});
    }
  }

  getTourData() {
    Loader(true);
    $.getScript('./assets/js/hopscotch.min.js');
    $.getScript('./assets/js/prism.min.js');
    this.formService.getTourData().subscribe(response => {
      this.tourData = response;
      if (this.tourData.step == 1) {
        $.getScript('./assets/js/tour.js');
      } else if (this.tourData.step == 2) {
        $.getScript('./assets/js/tour1.js');
      } else if (this.tourData.step == 3) {
        $.getScript('./assets/js/tour2.js');
      }
      Loader(false);
    }, error => {
      Loader(false);
    });
  }

  getFormList() {
    Loader(true);
    this.formService.getFormList().subscribe(response => {
      this.formList = response;
      this.setMenuArray(this.formList);
      Loader(false);
    }, error => {
      Loader(false);
    });
  }

  setMenuArray(data) {
    let linkCount = this.menuItems.length + data.length;
    let i = 1;
    this.menuItems[linkCount - i] = this.menuItems[this.menuItems.length - 1];
    data.forEach(el => {
      i++;
      this.menuItems[linkCount - i] = {
        path: '/form/list/' + el.id,
        title: el.formName,
        icon: el.menuIcon ? el.menuIcon : 'ft-home',
        class: '',
        badge: '',
        badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1',
        isExternalLink: false,
        allowPermissions: [],
        submenu: [],
      }
    })
  }


}
