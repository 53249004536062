import {Injectable} from "@angular/core";
import {environment} from '../environments/environment';
import * as SockJS from 'sockjs-client';
import * as Stomp from 'stompjs';
// import * as Kafka from 'no-kafka';
import * as express from 'express';

@Injectable()
export class WebSocketService {

  constructor() {
    this.webSocketConnection()
  }

  webSocketConnection(){
    let socket = new SockJS(environment.baseUrl+'websocket');
    let stompClient = Stomp.over(socket);
    stompClient.connect({}, function(frame) {
      stompClient.subscribe('/topic/temp',response=>{
        console.log("Message Is ---> "+ response);
      })

      // stompClient.subscribe('/topic/temp', function(error) {
      //   console.log("Error Message Is ---> "+error);
      // });
    });
  }
}
