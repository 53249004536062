import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { User } from '../models/user.model';
import { Observable } from 'rxjs';
import { NgxPermissionsService } from 'ngx-permissions';
import { TokenStorageService } from './token-storage.service';
import jwt_decode from 'jwt-decode';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class JWTAuthService {

  company: any = null;

  companyConfig = {
    company: {
      name: 'PAK Supermarket',
    },
    colorCodes: {
      primary: '#DB6778',
      secondary: '#ffa6b2',
    },
  };

  private timeoutId: any;

  // authApiUrl: string = 'https://paksupermarkets.com/auth-service';
  // apiUrl: string = 'https://paksupermarkets.com/';
  authApiUrl: string = `${environment.authUrl}`;
  // authApiUrl: string = `localhost:9085/auth-service/api`;
  // apiUrl: string = `${environment.baseUrl}api`;
  apiUrl: string =`${environment.authUrl}`;

  constructor(
    private router: Router,
    private http: HttpClient,
    private ngxPermissionsService: NgxPermissionsService,
    private tokenStorageService: TokenStorageService,
    private route: ActivatedRoute,
  ) {
  }

  /**
   * @description - This method is used to check if the user is logged in or not
   * if the user token available in the local storage set user permissions
   * @returns {boolean}
   * */
  load(): Promise<any> {
    return new Promise<boolean>(async (resolve) => {
      let data = {
        user: this.tokenStorageService.getUser(),
        token: this.tokenStorageService.getToken(),
      };
      if (data.user && data.token) {

      } else {
        const currentURL = window.location.href;
        const searchString = 'auth/sign-up';
        if (currentURL.includes(searchString)) {
        } else {
          this.logout();
        }
      }

      resolve(true);
      return data;
    });
  }

  /**
   * @description - This method is used to register the user
   * @param {User} user
   * @returns {Observable<User>}
   * */
  registerUser(user: any): Observable<User> {
      return this.http.post<User>(`${this.authApiUrl}auth-service/api/users/no-auth/create`, user);

  }

  /**
   * @description - This method is used to sign in the user
   * @param {User} user
   * @returns {Observable<User>}
   * */
  loginUser(user: User): Observable<User> {
    return this.http.post<User>(`${this.authApiUrl}auth-service/api/signin`, user);
  }

  getPermissionByRoleName(roleName : string) :Observable<any>{
    return this.http.get<any>(`${this.authApiUrl}auth-service/api/permission/role?name=${roleName}` );
  }

  /**
   * @description - This method is used to sign out the user
   * @param {User} user
   * @returns {Observable<User>}
   * */
  signOutUser(user: User): Observable<User> {
    return this.http.post<User>(`${this.apiUrl}/logout`, user);
  }

  /**
   * @description - This method is used to reset the password
   * */
  resetPassword(data: any): Observable<any> {
    return this.http.post<any>(
      `${this.authApiUrl}/auth/reset-password`,
      data,
    );
  }


  /**
   * @description - This method is used to reset the password when user logged in
   * */
  changePassword(data: any): Observable<any> {
    return this.http.put<any>(`${this.authApiUrl}/change-password`, data);
  }

  /**
   * @description - This method is used to send forgot password link to the user email
   **/
  forgotPassword(data: any): Observable<any> {
    return this.http.get<any>(
      `${this.authApiUrl}/auth/forgot-password?email=${data.email}`,
    );
  }

  /**
   * @description - This method is used to verify the two factor code
   * */
  twoFactorVerification(data: any): Observable<any> {
    return this.http.post<User>(
      `${this.authApiUrl}/auth/code/validate`,
      data,
    );
  }

  /**
   * @description - This method is used to generate the two factor code
   * */
  getTwoFactorConfigImage(): Observable<any> {
    let userData = this.tokenStorageService.getUser();
    return this.http.get<any>(
      `${this.authApiUrl}/auth/code/generate/paksupermarkets.com/${userData.email}`,
      { responseType: 'blob' as 'json' },
    );
  }

  /**
   * @description - This method is used to disable the authenticator app
   * */
  disableAuthenticatorApp() {
    return this.http.get(`${this.authApiUrl}/disable-authenticator-app`);
  }

  /**
   * @description - This method is used to change the workspace of the user
   * */
  workspace(workspace: any) {
    return this.http.post(`${this.authApiUrl}/workspace/change`, workspace);
  }


  /**
   * @description - This method is used to get the access token
   * */
  public getAccessToken() {
    let bearerToken = this.tokenStorageService.getToken();
    return 'Bearer ' + bearerToken;
  }

  /**
   * @description - This method is used to set the user token in local storage
   * */
  login(token: string): void {
    this.tokenStorageService.saveToken(token);
    this.router.navigate(['/']);
  }

  /**
   * @description - This method is used to logout the user
   * */
  logout(): void {
    this.tokenStorageService.signOut();
    clearTimeout(this.timeoutId);
    this.router.navigate(['/auth/sign-in']);
  }

  /**
   * @description - This method is used to reset the timeout of the user
   * after 10 minutes automatically logout the user
   * */
  resetTimeout() {
    clearTimeout(this.timeoutId);
    this.timeoutId = setTimeout(() => {
      this.logout();
    }, 600000); // 10 minutes in milliseconds
  }

  /**
   * @description - Checks if the user is authenticated based on the presence of a valid authentication token.
   * @returns `true` if the user is authenticated and the token is not expired; otherwise, `false`.
   * */
  isAuthenticated(): boolean {
    const token = this.tokenStorageService.getToken();
    // Check if the token is not expired
    if (token) {
      // You can add additional checks here if needed
      let decodedToken: any = jwt_decode(token);
      const currentTimestamp = Date.now() / 1000;
      return decodedToken.exp > currentTimestamp;
    }
    return false;
  }

  socialMediaAuthentication() {
    this.route.queryParams.subscribe((params) => {
      const token = params['token'];
      console.log(token);
      if (token) {
        this.login(token);
      }
    });
  }

  getCompany() {
    return this.company;
  }

  getUser() {
    return this.tokenStorageService.getUser();
  }

  getBranchList() {
    return this.http.post(`${this.apiUrl}/branch/no-auth/all`, {});
  }

}
