import { Component, ViewContainerRef } from '@angular/core';
import {WebSocketService} from './web-socket.service';
import {AppService} from './app.service';
import {NgxPermissionsService} from "ngx-permissions";
import {TokenStorageService} from "./auth/services/token-storage.service";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent {

  constructor(
    private appService: AppService,
    private permissionService:NgxPermissionsService,
    private tokenStorage:TokenStorageService
  ){
  }

  ngOnInit() {
    if(this.tokenStorage.getToken() && this.tokenStorage.getUser().id) {
      this.setPermissions();
    }
  }
  setPermissions(){
    let data = this.tokenStorage.getPermissions()
    data = JSON.parse(data);
    let stringArray: string[] = [...data];
    this.permissionService.addPermission(stringArray);
  }
}
