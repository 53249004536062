import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {TokenStorageService} from "../services/token-storage.service";
import {ToastrService} from "ngx-toastr";
import {JWTAuthService} from '../services/jwt-auth.service';

@Component({
  selector: 'app-two-factor-verification',
  templateUrl: './two-factor-verification.component.html',
  styleUrls: ['../auth-styles.scss', './two-factor-verification.component.scss']
})
export class TwoFactorVerificationComponent implements OnInit, AfterViewInit {

  form: any[] = ['', '', '', '', '', '']
  isLoading: boolean = false;
  email: string | null = '';

  @ViewChild('input0') input0: any;
  @ViewChild('input1') input1: any;
  @ViewChild('input2') input2: any;
  @ViewChild('input3') input3: any;
  @ViewChild('input4') input4: any;
  @ViewChild('input5') input5: any;

  constructor(
    private authService: JWTAuthService,
    private activatedRoute: ActivatedRoute,
    private route: Router,
    private tokenStorage: TokenStorageService,
    private toastr: ToastrService,
    private el: ElementRef
  ) {
  }

  ngOnInit(): void {
    if (!this.tokenStorage.tempToken){
      this.route.navigateByUrl('/auth/sign-in')
    }
    this.email = this.activatedRoute.snapshot.paramMap.get('email');
  }

  ngAfterViewInit(): void {
    this.input0.nativeElement.focus();
  }

  onSubmit() {
    this.isLoading = true;
    let code = this.concatenateArray(this.form);
    let data = {username: this.email, code: code};
    this.authService.twoFactorVerification(data).subscribe((data: any) => {
        if (data.valid) {
          this.tokenStorage.saveToken(this.tokenStorage.tempToken);
          this.tokenStorage.saveUser(this.tokenStorage.tempUser);
          window.location.reload();
        } else {
          this.toastr.error('Code is not valid',  'Error');
          this.isLoading = false;
        }
      }, (err) => {
        this.toastr.error('Something went wrong',  'Error');
        this.isLoading = false;
      }
    );
  }

  concatenateArray(array: string[]): string {
    let result = '';
    for (const element of array) {
      result += element;
    }
    return result;
  }

  focusNextOrPrevious(event: any, currentIndex: number, nextInput: any = null) {
    const inputElements = this.el.nativeElement.querySelectorAll('input[type="text"]');
    if (event.key === 'Backspace') {
      // Move to the previous input field if not already at the beginning
      if (currentIndex > 0) {
        inputElements[currentIndex - 1].focus();
      }
    } else {
      // Check if the current input field is filled
      if (currentIndex >= 0) {
        this.form[currentIndex] = event.target.value;
      }

      // Move to the next input field if not already at the end
      if (currentIndex < this.form.length - 1 && nextInput) {
        nextInput.focus();
      }
    }
  }

}
