<div class="col-lg-10 m-auto">
  <div class="row auth-forms-wrapper">

    <div class="col-md-12">
      <app-logo></app-logo>

      <div class="card form-section mt-4">
        <h3 class="auth-form-title mb-4">Verified!</h3>
        <div class="text-center">
          <svg xmlns="http://www.w3.org/2000/svg" width="65" height="65" viewBox="0 0 65 65" fill="none">
            <g clip-path="url(#clip0_6_1647)">
              <path d="M32.5 6.09375C39.5034 6.09375 46.2199 8.87583 51.172 13.828C56.1242 18.7801 58.9062 25.4966 58.9062 32.5C58.9062 39.5034 56.1242 46.2199 51.172 51.172C46.2199 56.1242 39.5034 58.9062 32.5 58.9062C25.4966 58.9062 18.7801 56.1242 13.828 51.172C8.87583 46.2199 6.09375 39.5034 6.09375 32.5C6.09375 25.4966 8.87583 18.7801 13.828 13.828C18.7801 8.87583 25.4966 6.09375 32.5 6.09375ZM32.5 65C41.1195 65 49.386 61.5759 55.481 55.481C61.5759 49.386 65 41.1195 65 32.5C65 23.8805 61.5759 15.614 55.481 9.51903C49.386 3.4241 41.1195 0 32.5 0C23.8805 0 15.614 3.4241 9.51903 9.51903C3.4241 15.614 0 23.8805 0 32.5C0 41.1195 3.4241 49.386 9.51903 55.481C15.614 61.5759 23.8805 65 32.5 65ZM46.8457 26.5332C48.0391 25.3398 48.0391 23.4102 46.8457 22.2295C45.6523 21.0488 43.7227 21.0361 42.542 22.2295L28.4502 36.3213L22.4834 30.3545C21.29 29.1611 19.3604 29.1611 18.1797 30.3545C16.999 31.5479 16.9863 33.4775 18.1797 34.6582L26.3047 42.7832C27.498 43.9766 29.4277 43.9766 30.6084 42.7832L46.8457 26.5332Z" fill="#00B473"/>
            </g>
            <defs>
              <clipPath id="clip0_6_1647">
                <rect width="65" height="65" fill="white"/>
              </clipPath>
            </defs>
          </svg>
          <p class="mt-3">You Have successfully verified your account.</p>
        </div>
      </div>

      <div class="card form-section mt-4">
        <h3 class="auth-form-title mb-4">Sorry Verification Failed!</h3>
        <div class="text-center">
          <svg xmlns="http://www.w3.org/2000/svg" width="65" height="65" viewBox="0 0 65 65" fill="none">
            <g clip-path="url(#clip0_6_1627)">
              <path d="M32.5 58.9062C39.5034 58.9062 46.2199 56.1242 51.172 51.172C56.1242 46.2199 58.9062 39.5034 58.9062 32.5C58.9062 25.4966 56.1242 18.7801 51.172 13.828C46.2199 8.87583 39.5034 6.09375 32.5 6.09375C25.4966 6.09375 18.7801 8.87583 13.828 13.828C8.87583 18.7801 6.09375 25.4966 6.09375 32.5C6.09375 39.5034 8.87583 46.2199 13.828 51.172C18.7801 56.1242 25.4966 58.9062 32.5 58.9062ZM32.5 0C41.1195 0 49.386 3.4241 55.481 9.51903C61.5759 15.614 65 23.8805 65 32.5C65 41.1195 61.5759 49.386 55.481 55.481C49.386 61.5759 41.1195 65 32.5 65C23.8805 65 15.614 61.5759 9.51903 55.481C3.4241 49.386 0 41.1195 0 32.5C0 23.8805 3.4241 15.614 9.51903 9.51903C15.614 3.4241 23.8805 0 32.5 0ZM22.2168 42.7832C21.0234 41.5898 21.0234 39.6602 22.2168 38.4795L28.1836 32.5127L22.2168 26.5459C21.0234 25.3525 21.0234 23.4229 22.2168 22.2422C23.4102 21.0615 25.3398 21.0488 26.5205 22.2422L32.4873 28.209L38.4541 22.2422C39.6475 21.0488 41.5771 21.0488 42.7578 22.2422C43.9385 23.4355 43.9512 25.3652 42.7578 26.5459L36.791 32.5127L42.7578 38.4795C43.9512 39.6729 43.9512 41.6025 42.7578 42.7832C41.5645 43.9639 39.6348 43.9766 38.4541 42.7832L32.4873 36.8164L26.5205 42.7832C25.3271 43.9766 23.3975 43.9766 22.2168 42.7832Z" fill="#FF3A3A"/>
            </g>
            <defs>
              <clipPath id="clip0_6_1627">
                <rect width="65" height="65" fill="white" transform="matrix(1 0 0 -1 0 65)"/>
              </clipPath>
            </defs>
          </svg>
          <p class="mt-3">Sorry, we could not verify your account. It may already be verified, or the verification code could be incorrect.</p>
        </div>
      </div>
    </div>
  </div>
</div>
