<div class="col-lg-10 m-auto">
  <div class="row align-items-center auth-forms-wrapper">
    <!--    <div class="col-lg-5 text-center auth-forms-left-panel">-->
    <!--      <app-auth-left-panel-->
    <!--        [title]="'Did You Forgot your password?'"-->
    <!--        [description]="'Please Enter your email address and we will send you instruction on how to reset your password.'"-->
    <!--      >-->
    <!--      </app-auth-left-panel>-->
    <!--    </div>-->

    <div class="col-lg-12">
      <div class="card form-section">
        <ngx-spinner
          type="ball-scale-multiple"
          size="medium"
          [fullScreen]="false"
        ></ngx-spinner>

        <div class="col-md-12 text-center mb-5">
          <app-logo></app-logo>
        </div>

        <h3 class="auth-form-title">Forgot Password</h3>

        <form
          name="form"
          (ngSubmit)="f.form.valid && onSubmit()"
          #f="ngForm"
          novalidate
          *ngIf="!submissionComplete"
        >
          <div class="form-group mb-3">
            <label for="email">Email</label>
            <input
              type="email"
              class="form-control"
              id="email"
              name="email"
              [(ngModel)]="form.email"
              required
              email
              #email="ngModel"
            />
            <div class="validation-msg" *ngIf="f.submitted && email.invalid">
              <!--              <div *ngIf="email.errors?.['required']">Email is required</div>-->
              <!--              <div *ngIf="email.errors?.['email']">Email must be a valid email address</div>-->
            </div>
          </div>

          <div class="form-group mb-3">
            <button class="btn-type-2 w-100" [disabled]="isLoading">
              <i class="fa-solid fa-arrows-rotate fa-spin"></i>
              Reset Password
            </button>
          </div>
        </form>

        <p class="mt-4" *ngIf="submissionComplete">
          A password reset link has been sent to your email. Please check your
          inbox and follow the instructions to reset your password. If you don't
          see the email in your inbox, please check your spam folder
        </p>

        <div class="form-group mb-3" *ngIf="submissionComplete">
          <p>Didn't receive the link? </p>
          <button class="btn-type-2 w-100" [disabled]="countDown > 0" (click)="submissionComplete = false">
            <ng-container>
              Resend <span style="padding-left: 5px" *ngIf="countDown > 0 && showCountDown">({{countDown}}s)</span>
            </ng-container>
          </button>
        </div>

        <div class="form-group mb-3 text-center">
          <a routerLink="/auth/sign-in" class="auth-link">Back to Sign In</a>
        </div>
      </div>
    </div>
  </div>
</div>
