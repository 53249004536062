
<div class="login-page  ">
  <div class="form-card p-4 shadow rounded">
    <div class="form-card-header mb-4 text-center">
      <img src="https://www.energy.gov.lk/templates/assets/img/logo.png" alt="Logo" class="img-fluid">
    </div>

    <ng-container>

      <form id="sign-up-form"
            (ngSubmit)="f.form.valid && onSubmit()"
            class="form"
            #f="ngForm"
            novalidate>

        <div class="form-group">
          <label for="firstName">First Name</label>
          <input type="text"
                 id="firstName"
                 name="firstName"
                 class="form-control input-bottom-border"
                 [(ngModel)]="form.signUpRequest.firstName"
                 required
                 minlength="3"
                 maxlength="20"
                 #firstName="ngModel">
          <div
            class="validation-msg"
            *ngIf="f.submitted && firstName.invalid"
          >
            <div *ngIf="firstName.errors?.required">
              First Name is required
            </div>
            <div *ngIf="firstName.errors?.minlength">
              First Name must be at least 3 characters
            </div>
            <div *ngIf="firstName.errors?.maxlength">
              First Name must be at most 20 characters
            </div>
          </div>
        </div>

        <div class="form-group">
          <label for="lastName">Last Name</label>
          <input type="text"
                 id="lastName"
                 name="lastName"
                 class="form-control input-bottom-border"
                 [(ngModel)]="form.signUpRequest.lastName"
                 required
                 minlength="3"
                 maxlength="20"
                 #lastName="ngModel">

          <div
            class="validation-msg"
            *ngIf="f.submitted && lastName.invalid"
          >
            <div *ngIf="lastName.errors?.required">
              Last Name is required
            </div>
            <div *ngIf="lastName.errors?.minlength">
              Last Name must be at least 3 characters
            </div>
            <div *ngIf="lastName.errors?.maxlength">
              Last Name must be at most 20 characters
            </div>
          </div>
        </div>

        <div class="form-group">
          <label for="email">Email</label>
          <input type="email"
                 id="email"
                 name="email"
                 class="form-control input-bottom-border"
                 [(ngModel)]="form.signUpRequest.email"
                 required
                 email
                 #email="ngModel">
          <div class="validation-msg" *ngIf="f.submitted && email.invalid">
          <div *ngIf="email.errors?.required">Email is required</div>
          <div *ngIf="email.errors?.email">
            Email must be a valid email address
          </div>
          </div>
        </div>

        <div class="form-group">
          <label for="companyName">Company Name</label>
          <input type="text"
                 id="companyName"
                 name="companyName"
                 class="form-control input-bottom-border"
                 [(ngModel)]="form.signUpRequest.companyName"
                 required
                 #companyName="ngModel">

          <div class="validation-msg" *ngIf="f.submitted && companyName.invalid">
            <div *ngIf="companyName.errors?.required">Company name is required</div>
            <div *ngIf="email.errors?.company">
              Email must be a valid company name
            </div>
          </div>
        </div>

        <!--      <div class="form-group">-->
        <!--        <label for="registration-number">Registration Number</label>-->
        <!--        <input type="text" id="registration-number" name="registration-number" class="form-control input-bottom-border" required>-->
        <!--      </div>-->

        <div class="form-group">
          <label for="password">Password</label>
          <input type="password"
                 id="password"
                 name="password"
                 [(ngModel)]="form.signUpRequest.password"
                 required
                 minlength="6"
                 #password="ngModel"
                 class="form-control input-bottom-border">

          <div class="validation-msg" *ngIf="f.submitted && password.invalid">
            <div *ngIf="password.errors?.required">
              Password is required
            </div>
            <div *ngIf="password.errors?.minlength">
              Password must be at least 6 characters
            </div>
          </div>
        </div>

        <div class="form-group">
          <label for="matchingPassword">Confirm Password</label>
          <input type="password"
                 id="matchingPassword"
                 name="matchingPassword"
                 class="form-control input-bottom-border"
                 [(ngModel)]="form.signUpRequest.matchingPassword"
                 minlength="6"
                 #matchingPassword="ngModel"
                 required>
          <div
            class="validation-msg"
            *ngIf="f.submitted && matchingPassword.invalid"
          >
            <div *ngIf="matchingPassword.errors?.required">
              Confirm Password is required
            </div>
            <div *ngIf="matchingPassword.errors?.minlength">
              Confirm Password must be at least 6 characters
            </div>
            <div *ngIf="matchingPassword.errors?.passwordMismatch">
              Passwords do not match.
            </div>
          </div>
        </div>

        <div class="back-login">
          <a href="http://">	&#x21A9; Back to Login</a>
        </div>

        <button
          class="form-button"
          [disabled]="isLoading"
          id="btnSignUp"
        >
          <i class="fa-solid fa-arrows-rotate fa-spin"></i>
          Sign Up
        </button>
        <p class="text-center mt-3">Already have an account? <a href="/auth/sign-in">Sign In</a></p>
      </form>



    </ng-container>
  </div>
</div>

