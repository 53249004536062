import { Component, OnInit } from '@angular/core';
import {JWTAuthService} from '../services/jwt-auth.service';

@Component({
  selector: 'app-auth-layout',
  templateUrl: './auth-layout.component.html',
  styleUrls: ['./auth-layout.component.scss']
})
export class AuthLayoutComponent implements OnInit {

  companyConfig = this.authService.companyConfig;

  constructor(
    private authService: JWTAuthService,

  ) { }

  ngOnInit(): void {
  }

}
