import { Injectable } from '@angular/core';
import {Restangular} from "ngx-restangular";

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(private restangular:Restangular) { }

  getCurrentDateSale() {
    return this.restangular.one('customer-order-summary/current-date-order-summary').customPOST();
  }

  getLastWeekSales() {
    return this.restangular.one('customer-order-summary/order-summary-previous-week').customGET();
  }

  getSummary() {
    return this.restangular.one('dashboard/summary').customGET();
  }

  getTourData() {
    return this.restangular.one('dashboard/tips').customGET();
  }

  pendingExtensionSummary() {
    return this.restangular.one('dashboard/pending-extension-summary').customGET();
  }

}
