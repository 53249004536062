<!-- Sidebar Header starts -->
<div class="sidebar-header">
  <div class="logo clearfix">
    <a [routerLink]="['/dashboard']" class="logo-text float-left">
      <img class="logo-letter-img" src="assets/img/logo-letter.png"/>
      <!--      <img class="logo-letter-img" style="height: 50px" src="assets/img/logo-letter.png">-->
      <img class="logo-img text align-middle text-center" src="assets/img/PAK_logo.png"/>
    </a>
    <a class="nav-toggle d-none d-sm-none d-md-none d-lg-block" id="sidebarToggle" href="javascript:;">
      <i class="ft-chevrons-right toggle-icon font-medium-4" data-toggle=""></i>
    </a>
    <a class="nav-close d-block d-md-block d-lg-none d-xl-none" id="sidebarClose" href="javascript:;">
      <i class="ft-x"></i>
    </a>
  </div>
</div>
<!-- Sidebar Header Ends -->

<!-- Sidebar Content starts -->
<div class="sidebar-content">
  <div class="nav-container" id="tour-start">
    <ul class="navigation">
      <!-- First level menu -->
      <ng-container *ngFor="let menuItem of menuItems">
        <li [ngClass]="[menuItem.class]" [routerLinkActive]="menuItem.submenu.length != 0 ? '' : 'active'"
            [routerLinkActiveOptions]="{exact: true}" *ngxPermissionsOnly="menuItem.allowPermissions">
          <a [routerLink]="menuItem.class === '' ? [menuItem.path] : null"
             *ngIf="!menuItem.isExternalLink; else externalLinkBlock" (click)="appService.mainMenu()">
            <i [ngClass]="[menuItem.icon]"></i>
            <span class="menu-title">{{menuItem.title | translate }}</span>
            <span *ngIf="menuItem.badge != '' " [ngClass]="[menuItem.badgeClass]">{{menuItem.badge}}</span>
          </a>
          <ng-template #externalLinkBlock>
            <a [href]="[menuItem.path]" target="_blank">
              <i [ngClass]="[menuItem.icon]"></i>
              <span class="menu-title">{{menuItem.title | translate }}</span>
              <span *ngIf="menuItem.badge != '' " [ngClass]="[menuItem.badgeClass]">{{menuItem.badge}}</span>
            </a>
          </ng-template>
          <!-- Second level menu -->
          <ul class="menu-content" *ngIf="menuItem.submenu.length > 0">
            <ng-container *ngFor="let menuSubItem of menuItem.submenu">
              <li [routerLinkActive]="menuSubItem.submenu.length > 0 ? '' : 'active'" [ngClass]="[menuSubItem.class]"
                  *ngxPermissionsOnly="menuSubItem.allowPermissions">
                <a [routerLink]="menuSubItem.submenu.length > 0 ? null : [menuSubItem.path]"
                   *ngIf="!menuSubItem.isExternalLink; else externalSubLinkBlock"
                   (click)="appService.mainMenu()">
                  <i [ngClass]="[menuSubItem.icon]"></i>
                  <span class="menu-title">{{menuSubItem.title | translate }}</span>
                  <span *ngIf="menuSubItem.badge != '' "
                        [ngClass]="[menuSubItem.badgeClass]">{{menuSubItem.badge}}</span>
                </a>
                <ng-template #externalSubLinkBlock>
                  <a [href]="[menuSubItem.path]">
                    <i [ngClass]="[menuSubItem.icon]"></i>
                    <span class="menu-title">{{menuSubItem.title | translate }}</span>
                    <span *ngIf="menuSubItem.badge != '' "
                          [ngClass]="[menuSubItem.badgeClass]">{{menuSubItem.badge}}</span>
                  </a>
                </ng-template>
                <!-- Third level menu -->
                <ul class="menu-content" *ngIf="menuSubItem.submenu.length > 0">
                  <ng-container *ngFor="let menuSubsubItem of menuSubItem.submenu">
                    <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
                        [ngClass]="[menuSubsubItem.class]" *ngxPermissionsOnly="menuSubsubItem.allowPermissions">
                      <a [routerLink]="[menuSubsubItem.path]"
                         *ngIf="!menuSubsubItem.isExternalLink; else externalSubSubLinkBlock"
                         (click)="appService.mainMenu()">
                        <i [ngClass]="[menuSubsubItem.icon]"></i>
                        <span class="menu-title">{{menuSubsubItem.title | translate }}</span>
                        <span *ngIf="menuSubsubItem.badge != '' "
                              [ngClass]="[menuSubsubItem.badgeClass]">{{menuSubsubItem.badge}}</span>
                      </a>
                      <ng-template #externalSubSubLinkBlock>
                        <a [href]="[menuSubsubItem.path]">
                          <i [ngClass]="[menuSubsubItem.icon]"></i>
                          <span class="menu-title">{{menuSubsubItem.title | translate }}</span>
                          <span *ngIf="menuSubsubItem.badge != '' "
                                [ngClass]="[menuSubsubItem.badgeClass]">{{menuSubsubItem.badge}}</span>
                        </a>
                      </ng-template>
                    </li>
                  </ng-container>
                </ul>
              </li>
            </ng-container>
          </ul>
        </li>
      </ng-container>
    </ul>
  </div>
</div>
<!-- Sidebar Content Ends -->
