import { Component, OnInit } from "@angular/core";
import { JWTAuthService } from "../services/jwt-auth.service";
import { ToastrService } from "ngx-toastr";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: "app-forgot-password",
  templateUrl: "./forgot-password.component.html",
  styleUrls: ["../auth-styles.scss", "./forgot-password.component.css"],
})
export class ForgotPasswordComponent implements OnInit {
  form: any = {};
  isLoading: boolean = false;
  submissionComplete: boolean = false;
  countDown: any = 15;
  showCountDown = false;

  constructor(
    private authService: JWTAuthService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
  ) {}

  ngOnInit(): void {
  }

  /**
   * @description - This function is used to submit the forgot password form
   * */
  onSubmit() {
    this.isLoading = true;
    this.spinner.show();
    this.authService.forgotPassword(this.form).subscribe(
      (data: any) => {
        this.submissionComplete = data.status;
        this.spinner.hide();
        this.startCountdown();
        this.toastr.success("Please check your email", "Success");
      },
      (err: any) => {
        this.isLoading = false;
        this.spinner.hide();
        this.toastr.error("Email not found", "Error");
      },
    );
    // this.isLoading = true;
    // this.store.dispatch(AuthActions.forgotPassword({ obj: this.form }));
  }

  startCountdown(){
    this.showCountDown = true;
    this.countDown = 15;
    setInterval(() => {
      this.countDown--;
      if (this.countDown === 0) {
        this.isLoading = false;
        this.showCountDown = false;
      }
    }, 1000)

  }

}
