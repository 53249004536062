import {HttpClient} from '@angular/common/http';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {environment} from '../environments/environment';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
export function RestangularConfigFactory (RestangularProvider, authService) {

  RestangularProvider.setBaseUrl(environment.baseUrl);
  // set static header
  RestangularProvider.setDefaultHeaders({'Authorization': 'Bearer ','Content-Type': 'application/json'});
  // by each request to the server receive a token and update headers with it
  RestangularProvider.addFullRequestInterceptor((element, operation, path, url, headers, params) => {
    authService.refreshToken();
    let bearerToken = authService.getToken();
    return {
      headers: Object.assign({}, headers, {'Authorization': 'Bearer ' + bearerToken})
    };
  });

  RestangularProvider.setErrorInterceptor( (response) => {
    if(response.status == 401){
      authService.logout();
    }
    return response;
  });

}

// export function initializeConfig(config: InitializerService) {
//   return (): Promise<any> => {
//     return new Promise((resolve, reject) => {
//       config.load().then(response => {
//         resolve(response);
//       });
//     });
//   };
// }

export function GetTenantId() {
  // console.log(window.location.hostname.split('.')[0]);
  return window.location.hostname;
}

export function randomString(length) {
  let text = "";
  let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

  for (let i = 0; i < length; i++){
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  return text;
}
