import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthRoutingModule } from './auth-routing.module';
import { SignUpComponent } from './sign-up/sign-up.component';
import { AuthLayoutComponent } from './auth-layout/auth-layout.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import { AuthSocialMediaComponent } from './auth-social-media/auth-social-media.component';
import { SignInComponent } from './sign-in/sign-in.component';
import { AuthLeftPanelComponent } from './auth-left-panel/auth-left-panel.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
// import {ConfirmDialogModule} from "primeng/confirmdialog";
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { TermsOfServiceComponent } from './terms-of-service/terms-of-service.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { VerificationComponent } from './verification/verification.component';
import { TwoFactorVerificationComponent } from './two-factor-verification/two-factor-verification.component';
import {ConfirmPasswordDirective} from "./directives/passwordMatchDirective";
import { LogoComponent } from './logo/logo.component';
import { NgbDatepickerModule} from "@ng-bootstrap/ng-bootstrap";
import {NgSelectModule} from "@ng-select/ng-select";
import { WorkspaceCreateComponent } from './workspace-create/workspace-create.component';
import {NgxSpinnerModule} from 'ngx-spinner';
import {ConfirmDialogModule} from "primeng/confirmdialog";


@NgModule({
  declarations: [
    SignUpComponent,
    AuthLayoutComponent,
    AuthSocialMediaComponent,
    SignInComponent,
    AuthLeftPanelComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    TermsOfServiceComponent,
    PrivacyPolicyComponent,
    VerificationComponent,
    TwoFactorVerificationComponent,
    ConfirmPasswordDirective,
    LogoComponent,
    WorkspaceCreateComponent,
  ],
  imports: [
    CommonModule,
    AuthRoutingModule,
    FormsModule,
    ConfirmDialogModule,
    NgbDatepickerModule,
    NgSelectModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
  ],
  providers: [],
})
export class AuthModule {}
