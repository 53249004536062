import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {FullLayoutComponent} from './layouts/full/full-layout.component';
import {ContentLayoutComponent} from './layouts/content/content-layout.component';
import {Full_ROUTES} from './shared/routes/full-layout.routes';
import {CONTENT_ROUTES} from './shared/routes/content-layout.routes';
import {AuthLayoutComponent} from "./auth/auth-layout/auth-layout.component";
import {BeforeAuthGuard} from "./auth/before-auth.guard";
import {AuthGuard} from "./auth/auth.guard";
import {Dashboard1Component} from "./dashboard/dashboard1/dashboard1.component";

const appRoutes: Routes = [


  {
    path: 'auth',
    component: AuthLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: './auth/auth.module#AuthModule',
      },
    ],
    canActivate: [BeforeAuthGuard],
  },
  {
    path: '',
    canActivate: [AuthGuard],
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },

  {path: '', component: FullLayoutComponent, data: {title: 'full Views'}, children: Full_ROUTES, canActivate: [AuthGuard]},
  {path: '', component: ContentLayoutComponent, data: {title: 'content Views'}, children: CONTENT_ROUTES,canActivate:[AuthGuard]},
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, {useHash: true})],
  exports: [RouterModule]
})

export class AppRoutingModule {
}
