import { Injectable } from '@angular/core';
import jwt_decode from 'jwt-decode';

const TOKEN_KEY = 'auth-token';
const USER_KEY = 'auth-user';
const PERMISISON_KEY = 'per';
const BRANCH_LIST = 'branch'

@Injectable({
  providedIn: 'root',
})
export class  TokenStorageService {
  tempToken: string = '';
  tempUser: any = {};

  constructor() {}

  /**
   * @description - Clears the local storage to sign out the user.
   */
  signOut(): void {
    localStorage.clear();
  }

  /**
   * @description - Saves the user's authentication token in local storage.
   * @param {string} token - The authentication token to save.
   */
  public saveToken(token: string): void {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.setItem(TOKEN_KEY, token);
  }


  public savepermissions(permissions: string): void {
    localStorage.removeItem(PERMISISON_KEY);
    localStorage.setItem(PERMISISON_KEY, permissions);
  }

  /**
   * @description - Retrieves the user's authentication token from local storage.
   * @returns {string} The authentication token or an empty string if not found.
   */
  public getToken(): any {
    return localStorage.getItem(TOKEN_KEY)
      ? localStorage.getItem(TOKEN_KEY)
      : '';
  }

  /**
   * @description - Retrieves and decodes the user's authentication token as an object from local storage.
   * @param {string} token - (Optional) The authentication token to decode. If not provided, the stored token will be used.
   * @returns {any} The decoded token object.
   */
  public getTokenObj(token: string = ''): any {
    if (token) {
      return jwt_decode(token);
    }
    // @ts-ignore
    let tokenStr: string = localStorage.getItem(TOKEN_KEY)
      ? localStorage.getItem(TOKEN_KEY)
      : '';
    return jwt_decode(tokenStr);
  }

  public getPermissions(): any {
    return localStorage.getItem(PERMISISON_KEY)
      ? localStorage.getItem(PERMISISON_KEY)
      : [];
  }

  // /**
  //  * @description - Updates the user's authentication token in local storage.
  //  * @param {string} token - The authentication token to update.
  //  * */
  // public updateToken(token: string): void {
  //   localStorage.removeItem(TOKEN_KEY);
  //   // localStorage.setItem(TOKEN_KEY, jwt_encode(token));
  // }

  /**
   * @description - Saves the user's data in local storage.
   * @param {any} user - The user data to save.
   */
  public saveUser(user: any): void {
    localStorage.removeItem(USER_KEY);
    localStorage.setItem(USER_KEY, JSON.stringify(user));
  }

  /**
   * @description - Retrieves the user's data from local storage.
   * @returns {any} The user data or an empty string if not found.
   */
  public getUser(): any {
    let user = localStorage.getItem(USER_KEY)
      ? localStorage.getItem(USER_KEY)
      : '';
    if (user) {
      return JSON.parse(user);
    }
    return '';
  }
}
