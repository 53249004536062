<!--Footer Starts-->
<footer>
    <div class="container-fluid">
        <p class="copyright text-center">
                Powered by <a id="edhiryaLink" href="https://edhirya.com" target="_blank">Edhirya IT</a>, All rights reserved.
        </p>

    </div>
</footer>
<!--Footer Ends-->
