import {Component, OnInit} from "@angular/core";
import {TokenStorageService} from "../services/token-storage.service";
import {Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {JWTAuthService} from "../services/jwt-auth.service";
import {NgxSpinnerService} from "ngx-spinner";
import {NgxPermissionsService} from "ngx-permissions";
import {Observable} from "rxjs";
import {Loader} from "../../util/helpers";
import {DashboardService} from "../../dashboard/dashboard.service";
// import {AUTH} from "../../core/services/constants";

@Component({
    selector: "app-sign-in",
    templateUrl: "./sign-in.component.html",
    styleUrls: ["../auth-styles.scss", "./sign-in.component.css"],
})
export class SignInComponent implements OnInit {
    form: any = {};
    isLoading: boolean = false;

    constructor(
        public tokenStorage: TokenStorageService,
        private authService: JWTAuthService,
        private router: Router,
        private toastr: ToastrService,
        private spinner: NgxSpinnerService,
        private dashboardService:DashboardService,
        private permissionService:NgxPermissionsService,
    ) {
    }

    ngOnInit(): void {
    }

    /**
     * Submits the login form for user authentication.
     * */
    onSubmit() {
        this.isLoading = true;
        this.spinner.show().then(r => this.spinner.hide());
        this.authService.loginUser(this.form).subscribe(
            (data: any) => {
                if (data && data.user.roles) {
                    this.login(data);
                    // let token = this.tokenStorage.getTokenObj(data.accessToken);
                    // if (token.user.enabledAuthenticatorApp) {
                    //   this.tokenStorage.tempToken = data.accessToken;
                    //   this.tokenStorage.tempUser = data.user;
                    //   this.router.navigateByUrl(
                    //     `/two-factor-verification/${data.user.email}`,
                    //   );
                    // } else {
                    //   this.login(data);
                    // }
                    this.spinner.hide();
                }else {
                    this.toastr.error("Email or password not matched", "Error");
                    this.isLoading = false;
                    this.spinner.hide();
                }

            },
            (err: any) => {
                console.log(err);
                this.toastr.error("Email or password not matched", "Error");
                this.isLoading = false;
                this.spinner.hide();
            },
        );
    }

    /**
     * Refresh the page and save the user data in the local storage
     * */
    login(data: any): void {
        this.tokenStorage.saveToken(data.accessToken);
        this.tokenStorage.saveUser(data.user);
        let permissions = JSON.stringify(data.permission);
        this.tokenStorage.savepermissions(permissions);
        let per = this.tokenStorage.getPermissions()
        per = JSON.parse(per);
        let stringArray: string[] = [...per];
        this.permissionService.addPermission(stringArray);
        this.router.navigateByUrl(
            `/`);
        // location.reload();
    }

    addPermission(role: string): Observable<any> {
        return new Observable((observer) => {
            this.authService.getPermissionByRoleName(role).subscribe(
                (data: any) => {
                    localStorage.setItem('per', JSON.stringify(data))
                    observer.next();
                    observer.complete();
                },
                (error: any) => {
                    observer.error(error);
                }
            );
        });
    }
}
