
import {APP_INITIALIZER, NgModule} from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from "./shared/shared.module";
import { ToastrModule } from 'ngx-toastr';
import { AgmCoreModule } from '@agm/core';
import {HttpClientModule, HttpClient, HTTP_INTERCEPTORS} from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { StoreModule } from '@ngrx/store';
import { DragulaModule } from 'ng2-dragula';

import { AppComponent } from './app.component';
import { ContentLayoutComponent } from "./layouts/content/content-layout.component";
import { FullLayoutComponent } from "./layouts/full/full-layout.component";


import * as $ from 'jquery';
import {AppService} from "./app.service";
import {environment} from "../environments/environment";
import {PreviousRouteService} from "./shared/routes/previous-route.service";
import {NgxPermissionsModule} from "ngx-permissions";
import {DROPZONE_CONFIG, DropzoneConfigInterface} from "ngx-dropzone-wrapper";
import {BsDatepickerModule} from "ngx-bootstrap";
import {PersistenceModule} from "angular-persistence";
import {CustomBreadcrumbModule} from './custom-breadcrumb/custom-breadcrumb.module';
import {WebSocketService} from './web-socket.service';
import {BrowserModule} from '@angular/platform-browser';
import {MaterialModule} from './common-components/material.module';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import {AuthModule} from "./auth/auth.module";

import {AuthInterceptor} from "./auth/services/auth-interceptor.service";
import {AuthGuard} from "./auth/auth.guard";
import {AuthService} from "./shared/auth/auth.service";
import {PermissionGuard} from "./shared/auth/permission-guard.service";
import {BreadcrumbModule} from "primeng/breadcrumb";
import {createTranslateLoader, RestangularConfigFactory} from "./app.util";
import {RestangularModule} from "ngx-restangular";

const DEFAULT_DROPZONE_CONFIG: DropzoneConfigInterface = {
  url: environment.baseUrl + 'upload/uploadFile',
  addRemoveLinks: true,
  dictRemoveFile: 'Remove',
  maxFilesize: 10,
  acceptedFiles: 'image/*,application/pdf,.xlsx,.docx',
  clickable: true,
  createImageThumbnails: true,
};


@NgModule({
  declarations: [
    AppComponent,
    FullLayoutComponent,
    ContentLayoutComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    PersistenceModule,
    StoreModule.forRoot({}),
    AppRoutingModule,
    SharedModule,
    DragulaModule.forRoot(),
    HttpClientModule,
    ToastrModule.forRoot(),
    NgbModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBr5_picK8YJK7fFR2CPzTVMj6GG1TtRGo'
    }),
    RestangularModule.forRoot([AuthService], RestangularConfigFactory),
    BsDatepickerModule.forRoot(),
    NgxPermissionsModule.forRoot(),
    CustomBreadcrumbModule,
    MaterialModule,
    NgxMaterialTimepickerModule,
    BreadcrumbModule,
    AuthModule
  ],
  providers: [
    {provide: DROPZONE_CONFIG, useValue: DEFAULT_DROPZONE_CONFIG},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    AuthService,
    AuthGuard,
    PermissionGuard,
    AppService, PreviousRouteService, WebSocketService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
