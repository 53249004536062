<app-logo></app-logo>
<div class="company-name">
  {{companyConfig.company.name}}
</div>
<div class="mt-5 hide-on-mobile">
  <h3>{{title}}</h3>
  <p>
    {{description}}
  </p>
  <button class="btn-type-1 mt-5 mx-auto" [routerLink]="link" *ngIf="link">
    {{linkText}}
  </button>
</div>
