import { Component, OnInit } from "@angular/core";
import { JWTAuthService } from "../services/jwt-auth.service";
import { ActivatedRoute, Router } from "@angular/router";
import { User } from "../models/user.model";
// import { ConfirmationService } from "primeng/api";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import {ConfirmationService} from "primeng/api";
// import {Countries} from "../../utill/country-list";

@Component({
  selector: "app-sign-up",
  templateUrl: "./sign-up.component.html",
  styleUrls: ["../auth-styles.scss", "./sign-up.component.scss"],
  providers: [ConfirmationService],
})
export class SignUpComponent implements OnInit {
  companyConfig = this.authService.companyConfig;
  form: User = new User();
  isLoading: boolean = false;
  countries = '';
  branchList: any[] = [];

  constructor(
    private authService: JWTAuthService,
    private confirmationService: ConfirmationService,
    private route: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
  ) {}

  ngOnInit(): void {
    /**
     * catch params  from url and set them to form object
     * */

    // this.getBranches();
  }

  /**
   * User registration form submit handler
   * */
  onSubmit(): void {
    debugger
    this.confirmSubmit();
  }

  /**
   * User registration form submit confirmation handler
   * @param form
   * */
  private confirmSubmit() {
    debugger
    this.isLoading = true;
    this.spinner.show();
    // @ts-ignore
    delete this.form.acceptTerms;

    var obj1 = {
      email: this.form.signUpRequest.email,
      firstName: this.form.signUpRequest.firstName,
      lastName: this.form.signUpRequest.lastName,
      password: this.form.signUpRequest.password,
      matchingPassword: this.form.signUpRequest.matchingPassword,
      username: this.form.signUpRequest.email,
      companyName: this.form.signUpRequest.companyName,
      // registrationNo: this.form.signUpRequest.registrationNo,
      enabled:true,
      displayName:this.form.signUpRequest.firstName + ' ' + this.form.signUpRequest.lastName,
      role:3 //company_admin
    }
    this.authService.registerUser(obj1).subscribe({
      next: () => {
        this.isLoading = false;
        this.spinner.hide();
        this.router.navigateByUrl("/auth/sign-in");
      },
      error: (error: any) => {
        this.toastr.error(error.message, "Error");
        this.spinner.hide();
        this.isLoading = false;
      },
    });
  }
}
