<div class="col-lg-10 m-auto">
  <div class="row auth-forms-wrapper">

    <div class="col-md-12">
      <app-logo></app-logo>

      <div class="card form-section mt-4">
        <h3 class="auth-form-title mb-4">Terms Of Service</h3>

        <h4>The Gist</h4>
        <p>
          We (the folks at Automattic) are on a mission to make the web a better place. We hope you love our products and services — from website publishing tools to ecommerce solutions to security backup s ystems tomanagement tools for distributed companies to the next great idea that we haven’t
          even thought of yet — as much as we love creating them.
          <br>
          These Terms of Service (“Terms”) describe our commitments to you, and your rights and responsibilities when using our services. Please read them carefully and reach out to us if you have any questions. If you don’t agree to these Terms, don’t use our services.
          <br>
          If you are a user in countries located in the European Economic Area, Switzerland, or the United Kingdom (“European User”), some special terms apply to you as mentioned below.
          <br>
          We’ve made these Terms available under a Creative Commons Sharealike license, which means that you’re more than welcome to copy them, adapt them, and repurpose them for your own use. Just make sure to revise them so that your Terms of Service reflect your actual practices. Also, if you
          do use these Terms, we’d appreciate a credit and link to Automattic somewhere on your website. You can grab a copy of these Terms and other legal documents on GitHub.
          <br>
          These Terms govern your access to and use of the products and services we provide through or for WordPress.com, WooCommerce.com, WooCommerce Shipping & Tax, WooPay, Jetpack.com, VaultPress.com, Happy.Tools, Jetpack CRM, MailPoet, WPScan.com, and WordPress.com Courses (collectively,
          “Services”). Please note that WooCommerce-related products and services are intended for commercial use and you acknowledge that your use is that of a representative of a business (as defined by applicable law) and not that of a consumer.
          <br>
          These Terms also govern visitors’ access to and use of any websites that use our Services, like the websites that our users create on WordPress.com. Please note though that the operators of those websites may also have their own separate terms of use.
        </p>
      </div>

    </div>

  </div>
</div>
