<div class="container">
  <div
    class="row align-items-center auth-forms-wrapper justify-content-center vh-100"
  >
    <div class="col-lg-8">
      <div class="card form-section p-5">
        <h3 class="auth-form-title">Create Your Workspace</h3>

        <form
          #f2="ngForm"
          (ngSubmit)="f2.form.valid && onSubmit()"
          class=""
          name="form2"
          novalidate
        >
          <fieldset [disabled]="isLoading">
            <div class="row mt-4">
              <div *ngIf="!form.tenantId" class="col-md-12 form-group mb-3">
                <label for="workSpace">Workspace Name</label>
                <input
                  #workSpace="ngModel"
                  [(ngModel)]="form.workSpace"
                  [pattern]="'^[a-zA-Z\\s]+$'"
                  class="form-control"
                  id="workSpace"
                  maxlength="20"
                  minlength="3"
                  name="workSpace"
                  required
                  type="text"
                />
                <div
                  *ngIf="f2.submitted && workSpace.invalid"
                  class="validation-msg"
                >
<!--                  <div *ngIf="workSpace.errors?.['required']">-->
<!--                    Workspace is required-->
<!--                  </div>-->
<!--                  <div *ngIf="workSpace.errors?.['pattern']">-->
<!--                    Workspace allows only letters-->
<!--                  </div>-->
<!--                  <div *ngIf="workSpace.errors?.['minlength']">-->
<!--                    Workspace must be at least 3 characters-->
<!--                  </div>-->
<!--                  <div *ngIf="workSpace.errors?.['maxlength']">-->
<!--                    Workspace must be at most 20 characters-->
<!--                  </div>-->
                </div>
              </div>
              <div class="col-md-12 form-group mb-3">
                <button
                  [disabled]="isLoading"
                  class="btn-type-2 w-100"
                  id="btnSignUp"
                >
                  <i class="fa-solid fa-arrows-rotate fa-spin"></i>
                  Create
                </button>
              </div>
            </div>
          </fieldset>
        </form>
      </div>
    </div>
  </div>
</div>

<p-confirmDialog
  [baseZIndex]="10000"
  [style]="{ width: '50vw' }"
  rejectButtonStyleClass="p-button-text"
></p-confirmDialog>
