import {Routes, RouterModule} from '@angular/router';
import {AuthGuard} from "../../auth/auth.guard";
// import {AuthGuard} from '../auth/keycloak-guard.service';

//Route for content layout with sidebar, navbar and footer.

export const Full_ROUTES: Routes = [

  {
    path: 'dashboard',
    loadChildren: './dashboard/dashboard.module#DashboardModule', canActivate: [AuthGuard]
  },
  {
    path: 'pages',
    loadChildren: './pages/full-pages/full-pages.module#FullPagesModule'
  },
  {
    path: 'usermanagement',
    loadChildren: './user-management/user-management.module#UserManagementModule', canActivate: [AuthGuard]
  },
  {
    path: 'log',
    loadChildren: './log/log.module#LogModule', canActivate: [AuthGuard]
  },
  {
    path: 'sections',
    loadChildren: './dynamic-section/dynamic-section.module#DynamicSectionModule', canActivate: [AuthGuard]
  },
  {
    path: 'forms',
    loadChildren: './forms/dforms.module#DFormsModule', canActivate: [AuthGuard]
  },
  {
    path: 'customer',
    loadChildren: './forms/customer/customer.module#CustomerModule', canActivate: [AuthGuard]
  },
  {
    path: 'form',
    loadChildren: './generated-form/generated-form.module#GeneratedFormModule', canActivate: [AuthGuard]
  },
  {
    path: 'company',
    loadChildren: './company-registration/company-registration.module#CompanyRegistrationModule', canActivate: [AuthGuard]
  },
  {
    path: 'my-tasks',
    loadChildren: './my-tasks/my-tasks.module#MyTasksModule', canActivate: [AuthGuard]
  },
  {
    path: 'inquiries',
    loadChildren: './inquiries/inquiries.module#InquiriesModule', canActivate: [AuthGuard]
  },
  {
    path: 'my-projects',
    loadChildren: './my-projects/my-projects.module#MyProjectsModule', canActivate: [AuthGuard]
  },
  {
    path: 'payments',
    loadChildren: './payments/payments.module#PaymentsModule', canActivate: [AuthGuard]
  },
  {
    path: 'form-wizard',
    loadChildren: './form-wizard/form-wizard.module#FormWizardModule', canActivate: [AuthGuard]
  },

  {
    path: 'sample',
    loadChildren: './sample/sample.module#SampleModule', canActivate: [AuthGuard]
  },

  {
    path: 'referring',
    loadChildren: './referring/referring.module#ReferringModule', canActivate: [AuthGuard]
  },

  {
    path: 'external',
    loadChildren: './external-approval/external-approval.module#ExternalApprovalModule', canActivate: [AuthGuard]
  },

  {
    path: 'committee',
    loadChildren: './committee/committee.module#CommitteeModule', canActivate: [AuthGuard]
  },
  {
    path: 'vat-configuration',
    loadChildren: './vat-configuration/vat-configuration.module#VatConfigurationModule', canActivate: [AuthGuard]
  },
  {
    path: 'map',
    loadChildren: './map/map.module#MapModule', canActivate: [AuthGuard]
  },
  {
    path: 'progress',
    loadChildren: './progress/progress.module#ProgressModule', canActivate: [AuthGuard]
  },
  {
    path: 'boards-paper',
    loadChildren: './boards-paper/boards-paper.module#BoardsPaperModule', canActivate: [AuthGuard]
  },
  {
    path: 'bonded-warehouse',
    loadChildren: './bonded-warehouse-facility/bonded-warehouse-facility.module#BondedWarehouseFacilityModule', canActivate: [AuthGuard]
  },
];
