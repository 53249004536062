<nav aria-label="breadcrumb">
  <ol class="breadcrumb p-0">
    <li class="breadcrumb-item" *ngFor="let breadcrumb of menuItems; let i = index">
      <ng-container *ngIf="(i + 1) < menuItems.length">
        <a [href]="breadcrumb.url">{{breadcrumb.label}}</a>
      </ng-container>
      <ng-container *ngIf="(i + 1) == menuItems.length">
        <span>{{breadcrumb.label}}</span>
      </ng-container>
    </li>
  </ol>
</nav>
