import {Component, Input, OnInit} from '@angular/core';
import {LocationStrategy} from '@angular/common';
import {JWTAuthService} from '../services/jwt-auth.service';


@Component({
  selector: 'app-auth-left-panel',
  templateUrl: './auth-left-panel.component.html',
  styleUrls: ['../auth-styles.scss', './auth-left-panel.component.css']
})
export class AuthLeftPanelComponent implements OnInit {

  @Input() title: string = '';
  @Input() description: string = '';
  @Input() link: string = '';
  @Input() linkText: string = '';

  companyConfig = this.authService.companyConfig;

  constructor(
    private authService: JWTAuthService,
    private locationStrategy: LocationStrategy
  ) {
  }

  ngOnInit(): void {
  }


}
