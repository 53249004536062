import {Injectable} from '@angular/core';
import {AuthService} from './shared/auth/auth.service';
import {Restangular} from 'ngx-restangular';
import {Router} from '@angular/router';
import {StorageType} from 'angular-persistence';
import {Breadcrumb} from './custom-breadcrumb/custom-breadcrumb/custom-breadcrumb.component';

@Injectable()
export class AppService {
  breadcrumbs: Breadcrumb[] = [];
  unitOfMeasures = [
    {'name': 'Unit'},
    {'name': 'Kilogram (Kg)'},
    {'name': 'Gram (g)'},
    {'name': 'Liter (l)'},
    {'name': 'Milliliter (ml)'},
  ];
  editorConfig = {
    editable: true,
    spellcheck: true,
    height: '10rem',
    minHeight: '250px',
    placeholder: 'Enter text here',
    translate: 'no',
    'toolbar': [
      ['bold', 'italic', 'underline'],
      ['fontName', 'fontSize'],
      ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull', 'indent', 'outdent'],
      ['cut', 'copy', 'delete', 'undo', 'redo'],
      ['horizontalLine', 'orderedList', 'unorderedList'],
      ['link']
    ]
  };
  public defaultPersistentStorage: StorageType = StorageType.SESSION;

  constructor(private authService: AuthService, private restangular: Restangular, private router: Router) {
  }

  public mainMenu() {
    this.breadcrumbs = [];
  }

  public setLoaderValue(value) {
    if (value) {
      $('#loader').show();
    } else {
      $('#loader').hide();
    }
  };

  public validateForm(form) {
    Object.keys(form.controls).forEach(field => { // {1}
      const control = form.get(field);            // {2}
      control.markAsTouched({onlySelf: true});       // {3}
    });
    return form.valid;
  }

  public dateToString(date) {
    date = new Date(date);
    date = date.getFullYear() + '-' + this.leftPad((date.getMonth() + 1), 2) + '-' + this.leftPad(date.getDate(), 2);
    return date;
  }

  public getAccessToken() {
    let bearerToken = this.authService.getToken();
    return 'Bearer ' + bearerToken;
  }

  public authUser() {
    return this.authService.getAuthUser();
  }

  public authPermission() {
    return this.authService.getPermission();
  }

  public login() {
    return this.authService.login('');
  }

  public removeFileFromServer(fileName) {
    return this.restangular.one('upload').customGET('removeFile', {fileName: fileName});
  }

  public leftPad(number, targetLength) {
    let output = number + '';
    while (output.length < targetLength) {
      output = '0' + output;
    }
    return output;
  }

}

export function downloadFile(fileName: string, response) {
  let url = (window.URL).createObjectURL(response);
  let anchor = document.createElement('a');
  document.body.appendChild(anchor);//required in FF, optional for Chrome
  anchor.download = fileName;
  anchor.href = url;
  anchor.click();
}
