import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {JWTAuthService} from './jwt-auth.service';
import {TokenStorageService} from "./token-storage.service";
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private tokenStorageService: TokenStorageService,
    private authService: JWTAuthService,
  ) {}

  /**
   * This HTTP interceptor is responsible for handling authentication tokens.
   * - For requests related to token validation ('auth/code/validate'), it adds the temporary token if available.
   * - For requests like user sign-in, user retrieval, and invited user retrieval, it passes the request through without modification.
   * - For other requests, it adds the user's authentication token to the request's Authorization header.
   *
   * @param request The incoming HTTP request.
   * @param next The HTTP handler for the next interceptor in the chain.
   * @returns An Observable of the HTTP event.
   */
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (request.url.includes('auth/code/validate')) {
      const token = this.tokenStorageService.tempToken;
      const authRequest = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token ? token : ''}`,
          // 'X-Trace-Id': randKey,
        },
      });
      return next.handle(authRequest);
    }
    if (
      request.url.includes('/signin') ||
      request.url == `${this.authService.apiUrl}/user` ||
      request.url == `${this.authService.apiUrl}/user/invited-user`
      // && !request.url.includes('auth/code/generate')
    ) {
      // If it is, simply pass the request through without modifying it
      return next.handle(request);
    }
    const token = this.tokenStorageService.getToken();
    /**
     * Clone the request and add the Authorization header
     * */
    const authRequest = request.clone({
      setHeaders: {
        // 'X-Trace-Id': randKey,
        Authorization: `Bearer ${token ? token : ''}`,
      },
    });

    // Pass the modified request to the next handler
    return next.handle(authRequest);
  }
}
