<div class="col-lg-10 m-auto">
  <div class="row align-items-center auth-forms-wrapper">
    <!--    <div class="col-lg-5 text-center auth-forms-left-panel">-->
    <!--      <app-auth-left-panel-->
    <!--        [title]="'Change Password'"-->
    <!--        [description]="'Once you change your password, it will take effect the next time you log in.'"-->
    <!--      >-->
    <!--      </app-auth-left-panel>-->
    <!--    </div>-->

    <div class="col-lg-12">
      <div class="card form-section">
        <ngx-spinner
          type="ball-scale-multiple"
          size="medium"
          [fullScreen]="false"
        ></ngx-spinner>

        <div class="col-md-12 text-center mb-5">
          <app-logo></app-logo>
        </div>

        <h3 class="auth-form-title">Reset Password</h3>

        <form
          name="form"
          (ngSubmit)="f.form.valid && onSubmit()"
          #f="ngForm"
          novalidate
        >
          <div class="form-group mb-3">
            <label for="password">Password</label>
            <input
              type="password"
              class="form-control"
              id="password"
              name="password"
              [(ngModel)]="form.newPassword"
              required
              minlength="6"
              #password="ngModel"
            />
            <div class="validation-msg" *ngIf="f.submitted && password.invalid">
              <!--              <div *ngIf="password.errors?.['required']">Password is required</div>-->
              <!--              <div *ngIf="password.errors?.['minlength']">Password must be at least 6 characters</div>-->
            </div>
          </div>

          <div class="form-group mb-3">
            <label for="matchingPassword">Confirm Password</label>
            <input
              type="password"
              class="form-control"
              id="matchingPassword"
              name="confirmPassword"
              [(ngModel)]="form.matchingPassword"
              required
              minlength="6"
              appConfirmPassword="password"
              #matchingPassword="ngModel"
            />
            <div
              class="validation-msg"
              *ngIf="f.submitted && matchingPassword.invalid"
            >
              <!--              <div *ngIf="matchingPassword.errors?.['required']">Confirm Password is required</div>-->
              <!--              <div *ngIf="matchingPassword.errors?.['minlength']">Confirm Password must be at least 6 characters</div>-->
              <!--              <div *ngIf="matchingPassword.errors?.['passwordMismatch']">Passwords do not match.</div>-->
            </div>
          </div>

          <div class="form-group mb-3">
            <button class="btn-type-2 w-100" [disabled]="isLoading">
              <i class="fa-solid fa-arrows-rotate fa-spin"></i>
              Reset Password
            </button>
          </div>
        </form>

        <div class="form-group mb-3 text-center">
          <a routerLink="/auth/sign-in" class="auth-link">Back to Sign In</a>
        </div>
      </div>
    </div>
  </div>
</div>
