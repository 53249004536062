<div class="col-lg-10 m-auto">
  <div class="row align-items-center auth-forms-wrapper">

    <div class="col-lg-5 text-center auth-forms-left-panel">
      <app-auth-left-panel
        [title]="'Did You Forgot your password?'"
        [description]="'Please Enter your email address and we will send you instruction on how to reset your password.'"
      >
      </app-auth-left-panel>
    </div>

    <div class="col-lg-7">
      <div class="card form-section">

        <h3 class="auth-form-title">Verify</h3>
        <p class="text-center">Enter the six-digit code that appears on your authentication app to verify your identity</p>

        <p class="text-center mt-3">Authentication Code</p>

        <form class="text-center" name="form" (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm" novalidate>

          <ul class="verification-code-list">
            <li>
              <div class="form-group">
                <input type="text" class="form-control" name="input0" [(ngModel)]="form[0]" id="input0" required maxlength="1" minlength="1" pattern="[0-9]*" #input0 (keyup)="focusNextOrPrevious($event, 0, input1)"/>
              </div>
            </li>
            <li>
              <div class="form-group">
                <input type="text" class="form-control" name="input1" [(ngModel)]="form[1]" id="input1" required maxlength="1" minlength="1" pattern="[0-9]*" #input1 (keyup)="focusNextOrPrevious($event, 1, input2)"/>
              </div>
            </li>
            <li>
              <div class="form-group">
                <input type="text" class="form-control" name="input2" [(ngModel)]="form[2]" id="input2" required maxlength="1" minlength="1" pattern="[0-9]*" #input2 (keyup)="focusNextOrPrevious($event, 2, input3)"/>
              </div>
            </li>
            <li>
              <div class="form-group">
                <input type="text" class="form-control" name="input3" [(ngModel)]="form[3]" id="input3" required maxlength="1" minlength="1" pattern="[0-9]*" #input3 (keyup)="focusNextOrPrevious($event, 3, input4)"/>
              </div>
            </li>
            <li>
              <div class="form-group">
                <input type="text" class="form-control" name="input4" [(ngModel)]="form[4]" id="input4" required maxlength="1" minlength="1" pattern="[0-9]*" #input4 (keyup)="focusNextOrPrevious($event, 4, input5)"/>
              </div>
            </li>
            <li>
              <div class="form-group">
                <input type="text" class="form-control" name="input5" [(ngModel)]="form[5]" id="input5" required maxlength="1" minlength="1" pattern="[0-9]*" #input5 (keyup)="focusNextOrPrevious($event, 5)"/>
              </div>
            </li>

          </ul>

          <div class="form-group mt-4 mb-3">
            <button class="btn-type-3" [disabled]="isLoading" id="btnVerify">
              <i class="fa-solid fa-arrows-rotate fa-spin"></i>
              Verify
            </button>
          </div>

        </form>

        <div class="form-group mb-3 text-center">
          <p class="mb-0">Having problems? <a routerLink="/auth/terms-of-service" class="auth-link">Use a recovery code</a></p>
          <p>Lose your recovery codes? <a routerLink="/auth/terms-of-service" class="auth-link">Contact our support</a></p>
        </div>

        <div class="form-group mb-3 text-center">
          <a routerLink="/auth/sign-in" class="auth-link">Back to Sign In</a>
        </div>

      </div>
    </div>
  </div>
</div>
