import {Component, OnInit} from '@angular/core';
import {JWTAuthService} from '../services/jwt-auth.service';

@Component({
  selector: 'app-verification',
  templateUrl: './verification.component.html',
  styleUrls: ['../auth-styles.scss', './verification.component.css']
})
export class VerificationComponent implements OnInit {

  companyConfig = this.authService.companyConfig;

  constructor(
    private authService: JWTAuthService,
  ) {
  }

  ngOnInit(): void {
  }

}
